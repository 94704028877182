<template>
  <div class="AddCollaborateur">
    <CModal
      :show.sync="isCollaborateurCreated"
      :no-close-on-backdrop="true"
      title="Collaborateur créé"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre collaborateur a bien été ajouté 👍
      </p>
      <p>
        Félicitations, votre collaborateur a bien été ajouté à votre espace Eclerk. Un mail automatique lui a été envoyé. Par mesure de sécurité, 
        veillez à lui communiquer le mot de passe que vous lui avez attribué. Celui-ci poura le changer sur son espace dès sa première connexion.
      </p>
      <template #footer>
        <CButton @click="$router.push('/administration/manage-collaborateurs')" color="primary">Retourner à la liste des collaborateurs</CButton>
      </template>
    </CModal>
    
    
    <CRow>
      <CCol>
        <h1> Ajouter un nouveau collaborateur </h1>
      </CCol>
    </CRow>
      <CCard class="mt-3">
        <CCardHeader>
          <strong>Informations générales</strong>
        </CCardHeader>
        <CCardBody>

          <CInput
            label="Email du collaborateur"
            type="email"
            maxlength="150"
            placeholder="Email"
            v-model="newCollaborateurEmail"
            @input="$v.newCollaborateurEmail.$touch(); isExistingNewUserEmail = false"
            :isValid="$v.newCollaborateurEmail.$dirty ? !$v.newCollaborateurEmail.$error : null"
          >
            <template #description>
              <small v-if="isExistingNewUserEmail" class="text-danger">
                {{ newCollaborateurEmail }} existe déjà. Veuillez choisir un autre nom d'utilisateur
              </small>
            </template>
          </CInput>

          <CInput
            label="Définissez un mot de passe pour votre nouveau collaborateur"
            type="password"
            maxlength="249"
            placeholder="Mot de passe"
            v-model="newCollaborateurPassword"
            @input="$v.newCollaborateurPassword.$touch()"
            :isValid="$v.newCollaborateurPassword.$dirty ? !$v.newCollaborateurPassword.$error : null"
            invalid-feedback="Votre mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial."
          >
          <template #description>
            <small class="text-info"> Votre collaborateur pourra changer son mot de passe sur son espace personnel </small>
          </template>
          </CInput>

          <CInput
            label="Confirmez le mot de passe"
            type="password"
            maxlength="249"
            placeholder="Confirmation du mot de passe"
            v-model="newCollaborateurConfirmPassword"
            @input="$v.newCollaborateurConfirmPassword.$touch()"
            :isValid="$v.newCollaborateurConfirmPassword.$dirty ? !$v.newCollaborateurConfirmPassword.$error : null"
            invalid-feedback="Veuillez rentrer deux mots de passe identiques"
          >
          </CInput>
          
        

          <CRow>
            <CCol sm="4">
              <CSelect
                :value.sync="newCollaborateurSex"
                label="Civilité"
                @input="$v.newCollaborateurSex.$touch()"
                :isValid="$v.newCollaborateurSex.$dirty ? !$v.newCollaborateurSex.$error : null"
                :options='userSexOptions'
                custom
              />
            </CCol>
            <CCol sm="4">
              <CInput
                label="Prénom"
                v-model="newCollaborateurFirstName" type="text"
                maxlength="30"
                placeholder="Prénom"
                @input="$v.newCollaborateurFirstName.$touch()"
                :isValid="$v.newCollaborateurFirstName.$dirty ? !$v.newCollaborateurFirstName.$error : null"
                invalid-feedback="Le prénom doit comporter entre 2 et 30 caractères"
              >
              </CInput>
            </CCol>
            <CCol sm="4">
              <CInput
                label="Nom"
                v-model="newCollaborateurLastName" type="text"
                maxlength="150"
                placeholder="Nom"
                @input="$v.newCollaborateurLastName.$touch()"
                :isValid="$v.newCollaborateurLastName.$dirty ? !$v.newCollaborateurLastName.$error : null"
                invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
              >
              </CInput>
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="6">
              <CSelect
                :value.sync="newCollaborateurFonction"
                label="Grade"
                @input="$v.newCollaborateurFonction.$touch()"
                :isValid="$v.newCollaborateurFonction.$dirty ? !$v.newCollaborateurFonction.$error : null"
                :options='userFonctionOptions'
                custom
              />
            </CCol>
            <CCol sm="6">
              <CInput
                label="Taux horaire (en € HT)"
                v-model="newCollaborateurTauxHoraire" type="number"
                placeholder="Taux horaire indicatif de votre collaborateur"
                @input="$v.newCollaborateurTauxHoraire.$touch()"
                :isValid="$v.newCollaborateurTauxHoraire.$dirty ? !$v.newCollaborateurTauxHoraire.$error : null"
              />

            </CCol>
          </CRow>

          <CRow>
            <CCol md="6">
              <label>Votre date de naissance </label>
              <CRow>
                <CCol sm="3">
                  <CSelect
                    :value.sync="newCollaborateurBirthdateDay"
                    :options='daysArrayForm'
                    custom
                  />
                </CCol>
                <CCol sm="6">
                  <CSelect
                    :value.sync="newCollaborateurBirthdateMonth"
                    :options='monthsArrayForm'
                    custom
                  />
                </CCol>
                <CCol sm="3">
                  <CSelect
                    :value.sync="newCollaborateurBirthdateYear"
                    :options='yearsArrayForm'
                    custom
                  />
                </CCol>
              </CRow>
              <small class="text-danger" v-if="$v.birthdateFormat.$invalid ">Veuillez saisir une date valide</small>
            </CCol>
            <CCol md="6">
              <div role="group" class="form-group">
                <label for="newCollaborateurVuePhoneNumber" class>
                  Numéro de téléphone
                </label>
                <vue-phone-number-input
                  id="newCollaborateurVuePhoneNumber"
                  name="newCollaborateurVuePhoneNumber"
                  v-model="newCollaborateurPhoneNumber"
                  :default-country-code='newCollaborateurPhoneNumberResult.countryCode'
                  :preferred-countries="['FR', 'BE', 'DE']"
                  valid-color='#28a745'
                  error-color='#dc3545'
                  :translations="{
                    countrySelectorLabel: 'Code pays',
                    countrySelectorError: 'Choisir un pays',
                    phoneNumberLabel: 'Numéro de téléphone',
                    example: 'Exemple :'
                  }"
                  @update='newCollaborateurPhoneNumberResult = $event'
                  :required='false'
                />
                <small class="text-danger" v-if="newCollaborateurPhoneNumberResult.isValid == false">Veuillez rentrer un numéro de téléphone valide</small>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader>
          <strong>Rôles de votre collaborateur</strong>
        </CCardHeader>
        <CCardBody>

          <CInputCheckbox
            :checked.sync="newCollaborateurIsManager"
          >
          <template #label>
            Votre collaborateur a-t-il le statut <CBadge shape="pill" color="danger">Manager</CBadge> ?
          </template>
          </CInputCheckbox>
            <p class="text-dark">
              Permissions du Manager: 
              <strong>Créer de nouvelles missions et y affecter des collaborateurs, éditer des factures et suivre la facturation, accéder au reporting des collaborateurs du cabinet</strong>.
            </p>
          <CInputCheckbox
            class="mt-3"
            :checked.sync="newCollaborateurIsAdmin"
          >
          <template #label>
            Votre collaborateur a-t-il le statut <CBadge shape="pill" color="primary">Administrateur</CBadge> ?
          </template>

          </CInputCheckbox>
          <p class="text-dark">
            Permissions de l'administrateur : 
            <strong>Créer de nouveaux collaborateurs, éditer les collaborateurs existants, ajouter et modifier les types de missions de votre cabinet</strong>.
          </p>
          
          <div v-if="userGroupContains(['BOSS'])">
            <CInputCheckbox
              class="mt-3"
              name="new-collaborateur-input-boss"
              :checked.sync="newCollaborateurIsBoss"
            >
            <template #label>
              Votre collaborateur a-t-il le statut <CBadge shape="pill" color="success">SuperAdministrateur</CBadge> ?
            </template>

            </CInputCheckbox>
            <p class="text-dark">
              Permissions du superadministrateur : 
              <strong>Gérer votre abonnement Eclerk ,gérer les informations de votre entreprise</strong> (nom, adresse, iban, rib, etc.), <strong>
                éditer les conventions d'honoraires types de votre cabinet</strong>
            </p>
            <p>
              <span v-if="newCollaborateurIsBoss" class="text-danger">
                <strong>Attention</strong>, ce collaborateur pourra accéder et modifier les informations de votre entreprise ainsi que 
                votre abonnement Eclerk. Habituellement, il n'y a pas plus de 3 superadministrateurs au sein d'un cabinet.
              </span>
            </p>
          </div>
          
          <CRow>
            <CCol>
              <p>
                ❓ Vous pouvez accéder au détail des rôles en <router-link to='/aide/role' target="_blank">cliquant ici</router-link>
              </p>
                
            </CCol>
          </CRow>
      </CCardBody>
    </CCard>
    
    <CButton
      @click="createCollaborateur" :color="createCollaborateurButtonStyle"
      shape="pill" block class="px-4 mb-4" size="lg"
      :disabled="$v.newCollaborateurEmail.$invalid || $v.newCollaborateurPassword.$invalid || $v.newCollaborateurConfirmPassword.$invalid || $v.newCollaborateurFirstName.$invalid || $v.newCollaborateurLastName.$invalid || $v.newCollaborateurSex.$invalid || $v.newCollaborateurFonction.$invalid || $v.birthdateFormat.$invalid || $v.newCollaborateurPhoneNumberResult.$invalid || $v.newCollaborateurTauxHoraire.$invalid || createCollaborateurInProcess">
      <CSpinner size="sm" label="update profile spinner" v-if="createCollaborateurInProcess"></CSpinner>
      {{ createCollaborateurButtonText }}
    </CButton>
  </div>
</template>

<script>

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, minValue, maxValue, email, sameAs, integer} from 'vuelidate/lib/validators'
import { password_validation, isDate } from '@/validators/validators'
import { APIAdminConnected } from '@/api/APIAdminConnected'
import userMixins from '@/mixins/userMixins'

const apiAdminConnected = new APIAdminConnected()


export default {
  name: 'AddCollaborateur',
  mixins: [
    validationMixin,
    userMixins
  ],
  data: function () {
    return {
      newCollaborateurEmail: '',
      isExistingNewUserEmail: false,
      newCollaborateurPassword: '',
      newCollaborateurConfirmPassword: '',
      newCollaborateurFirstName: '',
      newCollaborateurLastName: '',
      newCollaborateurSex: 'H',
      newCollaborateurFonction: 'COL',
      newCollaborateurTauxHoraire: '',
      newCollaborateurBirthdateYear: '1975',
      newCollaborateurBirthdateMonth: '01',
      newCollaborateurBirthdateDay: '01',
      newCollaborateurPhoneNumber: null,
      newCollaborateurPhoneNumberResult: {
        countryCode: "FR",
        isValid: false
      },
      newCollaborateurIsManager: false,
      newCollaborateurIsAdmin: false,
      newCollaborateurIsBoss: false,
      createCollaborateurButtonText: 'Ajouter un nouveau collaborateur',
      createCollaborateurButtonStyle: 'outline-primary',
      createCollaborateurInProcess: false,

      userSexOptions: [
        { label: 'Monsieur', value: 'H' },
        { label: "Madame", value: 'F' },
      ],
      userFonctionOptions: [
        { label: 'Collaborateur', value: 'COL' },
        { label: "Manager", value: 'MAN' },
        { label: "Associé", value: 'ASS' },
      ],

      isCollaborateurCreated: false,
      
      monthsArrayForm: [
        { label: 'Janvier', value: '01' },
        { label: 'Février', value: '02' },
        { label: 'Mars', value: '03' },
        { label: 'Avril', value: '04' },
        { label: 'Mai', value: '05' },
        { label: 'Juin', value: '06' },
        { label: 'Juillet', value: '07' },
        { label: 'Août', value: '08' },
        { label: 'Septembre', value: '09' },
        { label: 'Octobre', value: '10' },
        { label: 'Novembre', value: '11' },
        { label: 'Décembre', value: '12' },
      ],

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    
    // ---- Date 
    daysArrayForm () {
      var days = []
      for (var i=1; i<=31; i++) {
        var value = String(i)
        if (i <= 9) {
          value = "0" + String(i)
        }
        days.push({'text': value, 'value': value})
      }
      return days
    },
    
    yearsArrayForm () {
      var years = []
      for (var i=1945; i<=2010; i++) {
        var value = String(i)
        years.push({'text': value, 'value': value})
      }
      return years
    },
    
    birthdateFormat () {
      return this.newCollaborateurBirthdateYear + '-' + this.newCollaborateurBirthdateMonth + '-' + this.newCollaborateurBirthdateDay
    }
  },
  validations: {
    newCollaborateurEmail: {
      required,
      email,
      maxLength: maxLength(249),
      minLength: minLength(2)
    },
    newCollaborateurPassword: {
      required,
      password_validation,
      maxLength: maxLength(249),
    },
    newCollaborateurConfirmPassword: {
      required,
      maxLength: maxLength(249),
      sameAsPassword: sameAs('newCollaborateurPassword'),
    },
    newCollaborateurFirstName: {
      required,
      maxLength: maxLength(30),
      minLength: minLength(2)
    },
    newCollaborateurLastName: {
      required,
      maxLength: maxLength(150),
      minLength: minLength(2)
    },
    newCollaborateurSex: {
      required
    },
    newCollaborateurFonction: {
      required
    },
    newCollaborateurTauxHoraire: {
      required,
      integer,
      minValue: minValue(0),
      maxValue: maxValue(10000)
    },
    birthdateFormat: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    newCollaborateurPhoneNumberResult: {
      countryCode: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(2)
      },
      isValid: {
        isValid(value) {
          return value
        }
      },
      nationalNumber: {
        required,
        integer
      }
    },
    newCollaborateurIsManager: {
      required
    },
    newCollaborateurIsAdmin: {
      required
    },
    newCollaborateurIsBoss: {
      required
    }
  },
  methods: {

    createCollaborateur () {
      this.createCollaborateurButtonText = 'Création en cours'
      this.createCollaborateurButtonStyle = 'secondary'
      this.createCollaborateurInProcess = true
      apiAdminConnected.createNewUser(this.token,
        this.newCollaborateurEmail, this.newCollaborateurPassword, this.newCollaborateurConfirmPassword,
        this.newCollaborateurFirstName, this.newCollaborateurLastName, this.newCollaborateurSex, this.newCollaborateurFonction,
        this.newCollaborateurTauxHoraire, this.birthdateFormat, this.newCollaborateurPhoneNumberResult.countryCode,
        this.newCollaborateurPhoneNumberResult.nationalNumber, this.newCollaborateurIsManager, this.newCollaborateurIsAdmin, this.newCollaborateurIsBoss)
      .then(() => {
          this.isCollaborateurCreated = true
          this.resetNewUser()
      })
      .catch((error) => {
        try {
          if (error.response && error.response.data.username && error.response.data.username[0] === "Un utilisateur avec cet email existe déjà") {
            this.isExistingNewUserEmail = true
          }
          else {
            this.$store.commit('openGlobalErrorModal')
          }
        }
        catch {
          this.$store.commit('openGlobalErrorModal')
        }

      })
      .finally(() => {
        this.createCollaborateurButtonText = 'Ajouter un nouveau collaborateur'
        this.createCollaborateurButtonStyle = 'outline-primary'
        this.createCollaborateurInProcess = false
      })
    },

    resetNewUser () {
      this.newCollaborateurEmail = ''
      this.newCollaborateurPassword = ''
      this.newCollaborateurConfirmPassword = ''
      this.newCollaborateurSex = 'H'
      this.newCollaborateurFonction = 'COL'
      this.newCollaborateurFirstName = ''
      this.newCollaborateurLastName = ''
      this.newCollaborateurBirthdateYear = '1975',
      this.newCollaborateurBirthdateMonth = '01',
      this.newCollaborateurBirthdateDay = '01',
      this.newCollaborateurPhoneNumber = ''
      this.newCollaborateurIsManager = false
      this.newCollaborateurIsAdmin = false
      this.newCollaborateurIsBoss = false
    },

  }
}
</script>
